import React from "react";
import styled from "styled-components";

const boxenBeganeGrond = [
  {
    nr: 1,
    m2: 7.0,
    m3: 21.0,
    breedte: 200,
    lengte: 350,
    hoogte: 300,
    prijs: 97.0,
  },
  {
    nr: 2,
    m2: 8.5,
    m3: 25.0,
    breedte: 240,
    lengte: 350,
    hoogte: 300,
    prijs: 123.5,
  },
  {
    nr: 3,
    m2: 2.5,
    m3: 7.5,
    breedte: 120,
    lengte: 210,
    hoogte: 300,
    prijs: 40.0,
  },
  {
    nr: 4,
    m2: 2.8,
    m3: 8.5,
    breedte: 120,
    lengte: 230,
    hoogte: 300,
    prijs: 40.0,
  },
  {
    nr: 5,
    m2: 6.0,
    m3: 18.0,
    breedte: 240,
    lengte: 140,
    hoogte: 300,
    prijs: 85,
    breedte2: 120,
    lengte2: 210,
  },
  {
    nr: 6,
    m2: 5.5,
    m3: 16.5,
    breedte: 120,
    lengte: 230,
    hoogte: 300,
    prijs: 80.0,
    breedte2: 120,
    lengte2: 240,
  },
  {
    nr: 7,
    m2: 6.0,
    m3: 18.0,
    breedte: 170,
    lengte: 350,
    hoogte: 300,
    prijs: 90.0,
  },
  {
    nr: 8,
    m2: 4.0,
    m3: 12.0,
    breedte: 115,
    lengte: 350,
    hoogte: 300,
    prijs: 57.5,
  },
  {
    nr: 9,
    m2: 6.0,
    m3: 18.0,
    breedte: 170,
    lengte: 350,
    hoogte: 300,
    prijs: 90.0,
  },
  {
    nr: 10,
    m2: 4.0,
    m3: 12.0,
    breedte: 115,
    lengte: 350,
    hoogte: 300,
    prijs: 60.0,
  },
  {
    nr: 11,
    m2: 11.0,
    m3: 33.0,
    breedte: 310,
    lengte: 350,
    hoogte: 300,
    prijs: 155,
  },
  {
    nr: 12,
    m2: 5.0,
    m3: 15.0,
    breedte: 145,
    lengte: 350,
    hoogte: 300,
    prijs: 70.0,
  },
  {
    nr: 13,
    m2: 4.2,
    m3: 13.0,
    breedte: 120,
    lengte: 350,
    hoogte: 300,
    prijs: 57.5,
  },
  {
    nr: 14,
    m2: 11.0,
    m3: 33.0,
    breedte: 310,
    lengte: 350,
    hoogte: 300,
    prijs: 150.0,
  },
  {
    nr: 15,
    m2: 7.0,
    m3: 21.0,
    breedte: 200,
    lengte: 350,
    hoogte: 300,
    prijs: 95.0,
  },
  {
    nr: 16,
    m2: 5.0,
    m3: 15.0,
    breedte: 140,
    lengte: 350,
    hoogte: 300,
    prijs: 68.5,
  },
  {
    nr: 17,
    m2: 6.15,
    m3: 18.5,
    breedte: 175,
    lengte: 350,
    hoogte: 300,
    prijs: 90.0,
  },
  {
    nr: 18,
    m2: 11.0,
    m3: 33.0,
    breedte: 315,
    lengte: 350,
    hoogte: 300,
    prijs: 150.0,
  },
  {
    nr: 19,
    m2: 6.3,
    m3: 19.0,
    breedte: 180,
    lengte: 350,
    hoogte: 300,
    prijs: 90.0,
  },
  {
    nr: 20,
    m2: 11.2,
    m3: 33.6,
    breedte: 320,
    lengte: 350,
    hoogte: 300,
    prijs: 130.0,
  },
  {
    nr: 21,
    m2: 4.5,
    m3: 13.5,
    breedte: 125,
    lengte: 350,
    hoogte: 300,
    prijs: 62.5,
  },
  {
    nr: 22,
    m2: 4.5,
    m3: 13.5,
    breedte: 125,
    lengte: 350,
    hoogte: 300,
    prijs: 62.5,
  },
  {
    nr: 23,
    m2: 4.2,
    m3: 13.5,
    breedte: 120,
    lengte: 350,
    hoogte: 300,
    prijs: 62.5,
  },
  {
    nr: 24,
    m2: 16.0,
    m3: 48.0,
    breedte: 455,
    lengte: 350,
    hoogte: 300,
    prijs: 145.0,
  },
  {
    nr: 25,
    m2: 7.0,
    m3: 21.0,
    breedte: 200,
    lengte: 350,
    hoogte: 300,
    prijs: 65.0,
  },
  {
    nr: 26,
    m2: 3.4,
    m3: 10.0,
    breedte: 350,
    lengte: 0.95,
    hoogte: 300,
    prijs: 52.0,
  },
  {
    nr: 27,
    m2: 7.5,
    m3: 22.0,
    breedte: 190,
    lengte: 350,
    hoogte: 300,
    prijs: 115.0,
  },
];

const boxenEersteVerdieping = [
  {
    nr: 113,
    m2: 4.0,
    m3: 12.0,
    breedte: 175,
    lengte: 230,
    hoogte: 300,
    prijs: 60.0,
  },
  {
    nr: 114,
    m2: 4.85,
    m3: 14.5,
    breedte: 220,
    lengte: 230,
    hoogte: 300,
    prijs: 75.0,
  },
  {
    nr: 115,
    m2: 6.3,
    m3: 19.0,
    breedte: 275,
    lengte: 230,
    hoogte: 300,
    prijs: 95.0,
  },
  {
    nr: 116,
    m2: 4.85,
    m3: 14.5,
    breedte: 210,
    lengte: 230,
    hoogte: 300,
    prijs: 75.0,
  },
  {
    nr: 117,
    m2: 4.0,
    m3: 12.0,
    breedte: 175,
    lengte: 230,
    hoogte: 300,
    prijs: 60.0,
  },
  {
    nr: 118,
    m2: 4.85,
    m3: 14.5,
    breedte: 210,
    lengte: 230,
    hoogte: 300,
    prijs: 75.0,
  },
  {
    nr: 119,
    m2: 8.35,
    m3: 25.0,
    breedte: 355,
    lengte: 235,
    hoogte: 300,
    prijs: 125.0,
  },
  {
    nr: 120,
    m2: 8.35,
    m3: 25.0,
    breedte: 355,
    lengte: 235,
    hoogte: 300,
    prijs: 125.0,
  },
  {
    nr: 121,
    m2: 6.65,
    m3: 20.0,
    breedte: 285,
    lengte: 235,
    hoogte: 300,
    prijs: 100.0,
  },
];

const BoxContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BoxSubtext = styled.p`
  font-size: x-small;
  text-align: center;
`;

const TableContainer = styled.table`
  @media (max-width: 600px) {
    font-size: 0.6em;
  }

  @media (min-width: 600px) and (max-width: 769px) {
    font-size: 0.8em;
  }

  @media (min-width: 768px) {
    font-size: 1em;
  }
`;
const boxRow = (boxen) =>
  boxen
    .sort(function (a, b) {
      return a.nr - b.nr;
    })
    .map((x) => {
      return (
        <tr key={x.nr}>
          <td>{x.nr}</td>
          <td>{x.m3}</td>
          <td>{x.m2}</td>
          <td>
            {x.breedte} {x.breedte2 && `+ ${x.breedte2}`}
          </td>
          <td>
            {x.lengte} {x.lengte2 && `+ ${x.lengte2}`}
          </td>
          <td>{x.hoogte}</td>
          <td>&euro; {x.prijs.toFixed(2)}</td>
        </tr>
      );
    });

export const Boxen = () => (
  <BoxContainer>
    <h1> Units </h1>
    <p>
      Mini Opslag Deurne heeft units van verscheidene afmetingen. Zie hieronder
      de lijst met de prijzen. Andere afmetingen op aanvraag.
    </p>
    <div>
      <TableContainer>
        <thead>
          <tr>
            <th>Nummer</th>
            <th>
              m<sup>3</sup>
            </th>
            <th>
              m<sup>2</sup>
            </th>
            <th>Breedte (cm)</th>
            <th>Lengte (cm)</th>
            <th>Hoogte (cm)</th>
            <th>Prijs per maand</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={7}>
              <b>Begane grond</b>
            </td>
          </tr>
          {boxRow(boxenBeganeGrond)}
          <tr>
            <td colSpan={7}>
              <b>Eerste verdieping</b>
            </td>
          </tr>
          {boxRow(boxenEersteVerdieping)}
        </tbody>
      </TableContainer>
      <BoxSubtext>Prijswijzigingen en typefouten onder voorbehoud</BoxSubtext>
    </div>
    <p> Voor meer informatie kunt u altijd bellen of een email sturen. </p>
  </BoxContainer>
);

export default Boxen;
